import React from "react";
// import HeroSection from "../preMadeComponents/otherComponents/HeroSection/HeroSection";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";
import "../../src/myPages/Event.scss";

function Event() {
  return (
    <>
      <div className="event-section">
        {/* Sección de imágenes intercaladas */}
        <div className="image-section">
          <h2>Fun for Everyone:</h2>
          <div className="image-item">
            <img src={require("./images/open.jpeg")} alt="Imagen 1" />
            <p>
              From early hours, families and friends gathered in the heart of
              LeBaron, where festive energy filled the air. Live music and
              activities for kids created a vibrant and welcoming atmosphere.
              Attendees enjoyed games, rides, and entertainment for all ages,
              making unforgettable memories.
            </p>
          </div>
          <div className="image-item">
            <img src={require("./images/f12.jpeg")} alt="Imagen 1" />
            <p>
              <h2>Flavors of Tradition:</h2>
              One of the festival's major attractions was the numerous stalls
              offering products made with the exquisite nuts from the region.
              From cakes and cookies to pizzas and beverages, every bite was
              infused with the distinctive flavor of LeBaron nuts. Local chefs
              showcased their creativity in the kitchen, providing visitors with
              a unique culinary experience.
            </p>
          </div>
          <div className="image-item">
            <img src={require("./images/f9.jpeg")} alt="Imagen 1" />
            <p>
              <h2>Exploring the Stalls:</h2>
              Visitors had the chance to explore a wide range of stalls offering
              diverse products. Handicrafts, clothing, and local jewelry were
              mixed with the tempting aromas of food. Artisans and vendors
              shared their stories, creating a special connection between
              visitors and the community.
            </p>
          </div>
          <div className="image-item">
            <img src={require("./images/post.jpeg")} alt="Imagen 1" />
            <p>
              <h2>Desserts with Identity:</h2>
              The desserts became the stars of the festival, with creations
              ranging from fluffy donuts to delicately crafted cakes, all
              enhanced with the distinctive flavor of local nuts. The
              combination of textures and aromas created a symphony of sweetness
              that left attendees craving more.
            </p>
          </div>
          <div className="image-item">
            <img src={require("./images/post2.jpeg")} alt="Imagen 1" />
            <p>
              <h2>Exploring Creativity:</h2>
              But the festival was not just a delight for the palate; it was
              also a visual feast at the exploration stalls. From natural
              products to works of art, local artisans proudly showcased their
              creativity. Visitors had the opportunity to admire and purchase
              everything from sculptures to themed pinecones adorned with nuts,
              immersing themselves in the artistic richness of the community.
            </p>
          </div>
          <div className="image-item">
            <img src={require("./images/f11.jpeg")} alt="Imagen 1" />
            <p>
              <h2>Aromatic Finale and Memorable Goodbyes:</h2>
              As the sun set over the Nut Festival in LeBaron, the aromas of the
              consumed delicacies faded, leaving a trail of satisfaction and
              memories. As the lights of the stalls dimmed, the event lingered
              in the collective memory. The last notes of live music
              disappeared, but the laughter and conversations continued to
              resonate in the hearts of the participants. The tables, once
              filled with treats, now showed the remnants of a shared feast.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Event;
