import React from "react";
import "../../src/myPages/About.scss";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";

function About() {
  return (
    <>
      <div className="page-container">
        <div className="img-wrapper">
          <img src={require("./images/open.jpeg")} alt="Imagen 1" />
        </div>
        <div className="text-wrapper">
          <h1>"Welcome to the Galeana Pecan Festival"</h1>
          <p>
            In the heart of Lebarón, every year we celebrate with great joy and
            enthusiasm the traditional Nut Fair, an iconic event that honors the
            rich agricultural and cultural heritage of our community. In
            collaboration with the Galeana Pecan Festival, we are proud to
            present this celebration that highlights the importance of the nut
            in our region. For decades, the Nut Fair in Lebarón has been a
            meeting point for farmers, local producers, and food enthusiasts.{" "}
            <br />
            <br /> Founded with the purpose of promoting the nut industry and
            sharing our traditions with the world, this fair has become an
            eagerly anticipated annual event. During the fair, visitors have the
            opportunity to explore a wide variety of nut-related products, from
            fresh and roasted nuts to delicious sweets and oils. Our local
            farmers proudly showcase their harvests, sharing their passion for
            the land and its produce with everyone present. But the Nut Fair is
            much more than an agricultural market.
            <br />
            <br />
            It is a time of celebration and community, where families come
            together to enjoy live music, cultural performances, cooking
            contests, and a wide range of activities for all ages. It’s an
            opportunity to connect with our roots, share stories, and create
            lasting memories. At the Galeana Pecan Festival, we are committed to
            preserving our traditions and supporting our local farmers. We look
            forward to welcoming everyone to the next Nut Fair in Lebarón, where
            you can experience southern hospitality, delicious food, and the
            vibrant spirit of our community.
            <br />
            <br />
            "See you at the fair!"
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
