import { Nature } from "../../constants/constants_images";
export const heroSection__data = {
  mobileMaxScreenSize: 900,
  paddingY: 10,
  paddingX: 10,

  textOnImage: true,

   imageProperties: {
     image: Nature,
     imageBrightness: 0.9,

     isCirlce: false,
     borderRadius: 20,
   },

  textProperties: {
    title: "",
    titleFontSize: 22,
    titlefontWeight: 500,

    subtitle:
     "",
    subtitleFontSize: 12,
    subtitlefontWeight: 400,
  },

  linkButton: {
    displayLinkBtn: true,
    isImage: false,

    url: "https://www.youtube.com/watch?v=WrmNlBGjkws",
    isDifferentPage: true,

    linkPosition: "left",

    paddingY: 5,
    paddingX: 10,

    borderRadius: 3,

    marginTop: 90,

    imageStyles: {
      height: "auto",
      width: "95%",
      aspectRatio: "19/3",
    },

    btnStyles: {
      title: "Click Here watch the video!",
      backgroundColor: "#BE962E",
      textColor: "white",
      fontSize: 18,
      fontWeight: 400,
    },
  },
};
