import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../myPages/slideshow.scss";

const CarouselComponent = () => {
  return (
    <Carousel
      className="carousel-wrapper"
      autoPlay={true} 
      interval={5000} 
      showThumbs={false} 
      infiniteLoop={true} 
    >
      <div>
        <img src={require("./images/imagesslideshow/f1.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f2.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f3.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f4.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f5.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f6.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f7.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f8.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f9.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f10.jpeg")} alt="Imagen 1"/>
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f11.jpeg")} alt="Imagen 1" />
      </div>
      <div>
        <img src={require("./images/imagesslideshow/f12.jpeg")} alt="Imagen 1" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
