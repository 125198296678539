import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../myPages/slideshow.scss";

const CarouselComponent3 = () => {
  return (
    <Carousel
    className="carousel-wrapper"
    autoPlay={true}
    interval={5000} 
    showThumbs={false} 
    infiniteLoop={true} 
  >
      <div>
      <img src={require("./images/imagesslideshow/fr18.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr19.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr20.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr21.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr22.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr23.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr24.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr25.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr26.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr28.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr29.jpeg")} alt="Imagen 1" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent3;
