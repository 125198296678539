import React from "react";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";
import CarouselComponent from "../myPages/Slideshow";
import CarouselComponent2 from "../myPages/Slideshow2";
import CarouselComponent3 from "../myPages/Slideshow3";
import ReactPlayer from "react-player";
import "../myPages/Gallery.scss";

function Gallery() {
  return (
    <>
      <div className="container">
        <h1 className="title">
          "Every photo, a memory: enjoy the joy and cuisine of the Nut Festival"
        </h1>
        <p className="text">
          We fondly remember last year's event, where we enjoyed exciting
          contests, fun games, and a delicious culinary offering centered around
          nuts. <br /> <br />
          Additionally, the crafts and handmade products of all kinds, along
          with impressive art displays, made the fair a special place. All of
          this was captured in our photos, showcasing a family-friendly and warm
          atmosphere. We invite you to relive these moments and join us each
          year to create new unforgettable memories. Don't miss it!
        </p>
        <div className="grid">
          <div className="grid-item">
            <CarouselComponent />
          </div>
          <div className="grid-item">
            <CarouselComponent2 />
          </div>
          <div className="grid-item">
            <CarouselComponent3 />
          </div>
        </div>
        <h1 className="title">"From Nut to Art: Talent that Inspires"</h1>
        <p className="text">
          Come and discover how our talented artisans and artists transform nuts
          into magnificent works of art, opening the doors for many to visit and
          appreciate our craft.
        </p>
        <div className="grid">
          <div className="grid-item">
            <ReactPlayer
              className="player"
              url={require("./images/imagesslideshow/arte.mp4")}
              height="315"
              controls
              muted
              loop
              playing={true}
              playbackRate={0.8}
            />
          </div>

          <div className="grid-item">
            <ReactPlayer
              className="player"
              url={require("./images/imagesslideshow/juegos.mp4")}
              height="315"
              controls
              muted
              loop
              playing={true}
              playbackRate={0.8}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
