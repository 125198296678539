import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../myPages/slideshow.scss";

const CarouselComponent2 = () => {
  return (
    <Carousel
    className="carousel-wrapper"
    autoPlay={true} 
    interval={5000} 
    showThumbs={false} 
    infiniteLoop={true} 
  >

      <div>
      <img src={require("./images/imagesslideshow/fr5.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr6.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr7.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr8.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr9.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr12.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr13.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr14.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr15.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr16.jpeg")} alt="Imagen 1" />
      </div>
      <div>
      <img src={require("./images/imagesslideshow/fr17.jpeg")} alt="Imagen 1" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent2;
