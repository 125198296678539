import React, { useState } from "react";
import "../../src/myPages/Homepage.scss";

import HeroSection from "../preMadeComponents/otherComponents/HeroSection/HeroSection";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";

function HomePage() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const sendMessage = () => {
    const message = `Nombre: ${name}%0ANúmero: ${phone}`;
    const whatsappNumber = "+16361110307";
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  return (
    <div>
      <HeroSection />
      {/* Primera sección */}
      <section>
        <div className="section-container">
          <h2>"Galeana Pecan Festival"</h2>
          <p>
            "A treasure nestled in natural beauty, where community and pecans
            intertwine to create a unique experience. Why visit us? Because here
            you will find authentic flavors, deep-rooted tradition, and
            passionate people who bring to life every pecan we harvest. Come and
            experience the magic of Lebaron—we guarantee you won’t want to
            leave." <br /> <br />
            "We await you with open arms and the unmatched flavor of our
            pecans!"
          </p>
        </div>
      </section>

      {/* Segunda sección */}
      <section className="gray-section">
        <div className="section-container">
          <h2>
            "Pecans Straight from the Farm: Freshness and Irresistible Flavor"
          </h2>
          <div className="grid-container">
            <div className="grid-item">
              <img src={require("./images/nuez1.jpg")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/nuez2.jpeg")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/nuez3.jpg")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/nuez4.jpg")} alt="Imagen 1" />
            </div>
          </div>
        </div>
      </section>

      {/* Tercera sección */}
      <section>
        <div className="section-container">
          <h2>"Welcome to Our Community and the 2023 Pecan Festival!"</h2>
          <p>
            "It was a pleasure to welcome you to our community, a place rich in
            history, tradition, and flavor. We were thrilled to have you join us
            for the opening of the 2023 Pecan Festival, which kicked off on
            November 10th at 12 PM. This event was a celebration of everything
            that makes us unique, and we were eager to share it with you. Get
            ready to experience unforgettable moments!"
            <br /> <br /> "Among the main activities were our horseshoe and corn
            hole tournaments, as well as the food competition, which awarded
            prizes to the top winners. We also had the grand pecan art
            exhibition, showcased on our official event posters. To top it all
            off, we enjoyed the delightful Pecan Festival dance, which marked
            the end of this wonderful celebration. It was full of fun and
            excitement, and we want to invite everyone to join us next year—you
            won't want to miss it!"
          </p>
          <div className="grid-container">
            <div className="grid-item-blog">
              <img src={require("./images/invitacion.jpg")} alt="Imagen 1" />
            </div>

            <div className="grid-item-blog">
              <img src={require("./images/invitacion2.jpg")} alt="Imagen 1" />
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <section className="gray-section-two">
        <div className="section-container">
          <h2>"Games, Food, and More: A Day to Share with Family"</h2>
          <div className="grid-container">
            <div className="grid-item">
              <img src={require("./images/invitacion3.jpg")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/invitacion4.png")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/invitacion5.png")} alt="Imagen 1" />
            </div>

            <div className="grid-item">
              <img src={require("./images/invitacion6.jpg")} alt="Imagen 1" />
            </div>
          </div>
        </div>
      </section>

      {/* Cuarta sección para el contacto */}
      <section>
        <div className="section-container">
          <h2>Contact Us</h2>
          <div className="grid-container">
            <div className="grid-item-contacto">
              <h3>Contact Numbers</h3>
              <ul>
                <li>
                  <a href="tel:+1234567890">
                    <img src="" alt="" />Call Us At: +1234567890
                  </a>
                </li>
                <li>
                  <a href="tel:+0987654321">
                    <img src="" alt="" /> Call Us At: +0987654321
                  </a>
                </li>
              </ul>
              <h3>Registration</h3>
              <div className="image-maps">
                <img
                  src={require("./images/lebaron.jpg")}
                  alt="Imagen de la ubicación"
                />
              </div>
              <p>Register to receive updates</p>
              <form
                id="contact-form"
                style={{
                  backgroundColor: "#3c721c",
                  padding: "10px",
                  borderRadius: "5px",
                  marginBottom: "30px",
                }}
              >
                <label htmlFor="name"> Name:    </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <br />
                <label htmlFor="phone">Number: </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <br />
                <button type="button" onClick={sendMessage}>
                  Registrarse
                </button>
              </form>
            </div>

            <div className="grid-item-contacto">
              <h3>Location</h3>
              <p>
              Alma Dayer School, CP. 31870, Colonia Lebaron, Chihuahua.
                <br />
                Friday 12:00 PM to 6:00 PM <br />
                Saturday 12:00 PM to 6:00 PM
              </p>
              <div className="image-maps">
                <p>"Google Maps map here"</p>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d863.7271162673637!2d-107.57278171504015!3d30.010784608632637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86c3172de6475bcb%3A0x3e8c11977088b879!2sLebaron%20park!5e0!3m2!1ses-419!2smx!4v1723586653615!5m2!1ses-419!2smx"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Ubicación en Google Maps"
                ></iframe>
                <a
                  href="https://maps.app.goo.gl/R4TXQu6Vd4LfeePZ8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 View on Google Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default HomePage;
