import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Navbar from "./preMadeComponents/navigation/Navbar/Navbar";

// Pages
import HomePage from "./myPages/HomePage";
import About from "./myPages/About";
import Event from "./myPages/Event"; 
import Gallery from "./myPages/Gallery";
import PageNotFound from "./preMadeComponents/pageNotFound/PageNotFound";
import Sponsors from "./myPages/Sponsors";


function App() {
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="event" element={<Event />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="sponsors" element={<Sponsors/>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
