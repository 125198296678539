import React from "react";

import "./Footer.scss";

import { footer__data } from "../../../myFolder/editComponents/other/footerData";

function Footer() {
  const {
    websiteName,
    contactEmail,
    businessAddress,
    mainColors,
    logoImage,
    socialMedias,
  } = footer__data;

  return (
    <div
      className="footer"
      style={{
        backgroundColor: mainColors.backgroundColor,
        color: mainColors.textColor,
      }}
    >
      <div className="logoImageContainer">
        <img
          src={logoImage.image}
          alt=""
          className="logoImage"
          draggable="false"
          style={{ height: logoImage.height }}
        />
      </div>

      <div className="emailAndAddressContainer">
        <div className="info">
          {contactEmail &&
            `
            Email: ${contactEmail}
            `}
        </div>
        <div className="info">
          {businessAddress && `Address: ${businessAddress}`}
        </div>
      </div>

      <div className="allSocialMediasContainer">
        {socialMedias.map((socialMedia, index) => {
          return (
            <a
              href={socialMedia.socialMediaLink}
              target="_blank"
              rel="noopener noreferrer"
              className="socialMediaIconContainer"
              style={
                mainColors.socialMediaIconsColor === ""
                  ? { color: mainColors.socialMediaIconsColor }
                  : mainColors.socialMediaIconsColor === "custom"
                  ? { color: socialMedia.iconCustomColor }
                  : { color: mainColors.socialMediaIconsColor }
              }
            >
              {socialMedia.icon}
            </a>
          );
        })}
      </div>

      <div className="copyrightSection">
        <div className="copyrightAndBusinessName">
          &copy; 2024 {websiteName} | ALL RIGHTS RESERVED
        </div>

        <div className="createdBySection">
          This website was created by Whistle Coding
        </div>
      </div>
    </div>
  );
}

export default Footer;
