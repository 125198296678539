// Import images here
import logo from "../../defaultStuff/placeholderImages/logo placeholder.png";
import nature from "../../defaultStuff/placeholderImages/nature.png";


// Export images here
export const websiteLogo = logo;
export const Nature = nature;



