import React from "react";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";
import "../myPages/Sponsors.scss";

function Sponsors() {
  return (
    <div>
      <div className="container">
        <h1 className="title">
          "Galeana Biz: Proud Sponsor of the Nut Festival."
        </h1>
        <p className="text">
          ¡Everyone is invited to visit our website galeanabiz.com! At Galeana
          Biz, the entire LeBaron community has access to the free delivery
          service from all our participating businesses, bringing the
          convenience of our stores right to your doorstep." <br /> <br />
          Remember, if you have your account with LeBaron Galeana, you enjoy
          these benefits and many more. And if you're not registered yet, we
          invite you to sign up! Don’t miss out on this opportunity and enjoy
          efficient and free service with Galeana Biz.
        </p>
      </div>
      <div className="image-center">
        <a
          href="https://galeana.biz/home-business"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("./images/imageslogos/lgb.jpeg")}
            alt="herradero"
            className=""
          />
        </a>
        <div className="logo-section">
          <div className="logo-row">
            <a
              href="https://galeana.biz/business/74"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n1.jpg")}
                alt="carnitas"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/60"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n3.jpg")}
                alt="rays"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/46"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n4.jpg")}
                alt="lebirria"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/66"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n6.jpg")}
                alt="edredon"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/26"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n8.jpg")}
                alt="blue-mountain"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/47"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n9.jpg")}
                alt="taco-camaron"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/57"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n11.jpeg")}
                alt="hakuna"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/44"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n12.jpeg")}
                alt="cecilias"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/42"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n13.jpeg")}
                alt="chicaboom"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/68"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n14.jpeg")}
                alt="el-paisa"
                className="logo"
              />
            </a>
            <a
              href="https://galeana.biz/business/43"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("./images/imageslogos/n15.jpeg")}
                alt="Logo 11"
                className="logo"
              />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Sponsors;
