import { websiteName } from "../../constants/constants_info";
import { websiteLogo } from "../../constants/constants_images";
 export const navbar__data = {
   pageLinks: [
     {
       text: "Home",
       url: "/",
       isDifferentPage: false,
       openNewTab: false,
     },
     {
       text: "About",
       url: "/about",
       isDifferentPage: false,
       openNewTab: false,
     },
     {
       text: "Event",
       url: "/Event",
       isDifferentPage: false,
       openNewTab: false,
     },
     {
       text: "Gallery",
       url: "/gallery",
       isDifferentPage: false,
       openNewTab: false,
     },
     {
       text: "Sponsors",
       url: "/Sponsors",
       isDifferentPage: false,
       openNewTab: false,
     },
  
   ],
   navbarStickyOrInline: "sticky",
   navbarDirection: "vertical",
   gapBetweenLogoAndLinks: 10,
   navbarJustifyContent: "space-between",
   heightAndPadding: {
     navbarHeight: null,
     paddingY: 10,
     paddingX: 5,
   },

   mainColors: {
     backgroundColor:"#CAA75D",
     textColor: "black",
     linkClickedOrHoveredColor: "green",
     hamburgerColor: "#384E21",
   },
   logoImage: {
     image: websiteLogo,
     imageHeight: 150,
 
  
   },
   linksStyles: {
     gapBetweenLinks: 40,
     fontSize: 18,
     fontWeight: 400,
     paddingY: 0,
     paddingX: 3,
     borderRadius: 0,
     linkHoverEffect: "underline",
     linkHoverEffectUnderlineEffectHeight: 3,
   },
   mobileStyles: {
     mobileMaxScreenSize: 820,
     mobileNavbarType: "hamburger",
     mobileNavbarStyles: {
       logoImageHeight: 80,
       linksFontSize: 16,
       hamburgerPosition: 5, // From Right
       hamburgerSize: 42,
     },
     mobileSidebarStyles: {
       titleText: websiteName,
     },
   },
 };

// // ------------------------------------------------
// // ------------------------------------------------
// // Stuff I'm not using anymore, but still might use
// // ------------ SO DONT DELETE ANYTHING -----------
// // ------------------------------------------------
// // ------------------------------------------------

// // Title
// // titleText: websiteName,
// // titleFontSize: 22,
// // titleFontWeight: 500,

